@media (max-width: 414px) {
  .list {
    gap: 1rem;
    flex-direction: column;
    align-items: center;
  }
}


label {
  display: block;
  margin-top: 5px;
}

a:hover {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.card-container.card {
  max-width: 458px !important;
  padding: 30px 30px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.containerIMG { 
  position:relative;
  padding:0;
  margin:0;
}
.input { 

  margin:0;
  padding-right:30px;
}
.image {
  position:absolute;
  bottom:8px;
  right:10px;
  width:22px;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.card{
    transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);

}
#familycard{
  width: 40rem;
}

@media only screen and (max-width: 600px) {
  #familycard{
    width: 20rem;
  }
}






#vagascard{
  cursor: pointer;
}


.card:hover{
  /* transform: scale(1.01);
box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06); */
}


